import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"


const IndexPage = () => (
  
  <Layout>
    <SEO title="Home Page"/>
    <div>
      <Hero/>
      <h1 className='title'>Griffin Barlow's Stuff</h1>
    </div>
  </Layout>
)

export default IndexPage