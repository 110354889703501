import React from "react"

import "./layout.css"
import HeroImage from "../images/hero-image.png"

const Hero = () => {
    return (
    <div>
        <img src= { HeroImage }/>
    </div>
        
    )
}

export default Hero
